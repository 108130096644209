<template>
  <div class="detail-info-panel w-l-column-center-compact">
    <div class="img-block">
      <img :src="imgUrl" height="490" alt="">
    </div>
    <div class="title">{{title}}</div>
    <div class="content">
<!--      {{content}}-->
    </div>
  </div>
</template>

<script>
  export default {
    name: "DetailInfoPanel",
    props: {
      imgUrl: String,
      title: String,
      content: String
    }
  }
</script>

<style lang="scss" scoped>
.detail-info-panel{
  margin: 50px 0;
  .img-block{
    margin: 50px 0;
  }
  .title{
    color: #202425;
    font-size: 30px;
    margin: 40px 0;
  }
  .content{
    font-size: 16px;
    color: rgba(32,36,37,0.50);
  }
}
</style>
