<template>
  <div class="ai-service-icon-block w-l-column-center-compact">
    <div class="content w-l-column-center-compact" :class="{'active': isActive}" v-on:click="contentClick">
      <div class="icon-container w-l-row-center-compact" :style="{'background-color': bgColor}">
        <i class="iconfont" :class="iconClass"></i>
      </div>
      <div class="desc font-12 fc-white">
        {{ iconDesc }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AiServiceIcon",
  props:{
    iconClass: String,
    iconDesc: String,
    index:{
      type: Number,
      require:true
    },
    bgColor:{
      type: String,
      default: () => '#17D'
    },
    curSubIndex:{
      type: Number,
      default: () => 0
    }
  },
  computed:{
    isActive(){
      return this.index === this.curSubIndex
    }
  },
  methods:{
    contentClick(){
      this.$emit("click", this.index)
    }
  }
}
</script>

<style lang="scss" scoped>
.ai-service-icon-block{
  padding: 0 55px;
  border-right: lightgrey 1px solid;
  width: 220px;
  height: 180px;
  .icon-container{
    height: 60px;
    width: 60px;
    border-radius: 50%;
    .iconfont{
      color: white;
      font-size: 20px !important;
    }
  }
  .desc{
    margin-top: 20px;
  }
  .content{
    width: 200px;
    height: 150px;
    &:hover{
      cursor: pointer;
    }
    &.active{
      border-radius: 10px;
      background-color: rgba(35, 176, 77, 0.7)
    }
  }
}
.ai-service-icon-block:last-child{
  border-right: none;
}
</style>
