<template>
  <wit-block-content-container class="service-info-panel" :style="{'background-color': bgColor}">
    <div class="panel-content w-l-column-center-compact">
      <div class="block-title">{{ blockTitle }}</div>
      <detail-info-panel v-for="(item, index) in detailContent"
                         :key="index"
                         :img-url="item.imgUrl"
                         :title="item.title"
                         :content="item.content"/>
    </div>
  </wit-block-content-container>
</template>

<script>
  import DetailInfoPanel from "@/views/plantation/ServiceInfoPanel/DetailInfoPanel";
  import WitBlockContentContainer
    from "@/components/common/WitBaseUI/WitBlockContentContainer/WitBlockContentContainer";
  export default {
    name: "ServiceInfoPanel",
    components:{
      DetailInfoPanel,
      WitBlockContentContainer
    },
    props:{
      blockTitle: String,
      detailContent: Array,
      bgColor: {
        type: String,
        default: () => "rgb(255,255,255)"
      }
    }
  }
</script>

<style lang="scss" scoped>
.service-info-panel{
  .panel-content{
    .block-title{
      font-size: 60px;
      margin-bottom: 50px;
      margin-top: 100px;
    }
  }
}
</style>
