<template>
  <wit-block-content-container class="brief-panel">
    <div class="panel-content w-l-column-center-compact">
      <div class="panel-title">{{ title }}</div>
      <div class="function-select-block w-l-row-center-compact">
        <function-sel-panel :index="0" :cur-index="curFuncIndex" @click="funcClick">感知</function-sel-panel>
        <function-sel-panel :index="1" :cur-index="curFuncIndex" @click="funcClick">认知</function-sel-panel>
        <function-sel-panel :index="2" :cur-index="curFuncIndex" @click="funcClick">决策</function-sel-panel>
      </div>
      <div class="icon-desc-block w-l-row-center-compact">
        <ai-service-icon v-for="(item, index) in content[curFuncIndex]"
                         :key="index"
                         :index="index"
                         @click="subClick"
                         :cur-sub-index="curSubIndex"
                         :icon-class="item.iconClass"
                         :icon-desc="item.title"
                         :bg-color="item.bgColor"/>
      </div>
      <div class="img-block w-l-row-center-compact">
        <img :src="curImgUrl" height="400" alt="">
      </div>
    </div>
  </wit-block-content-container>
</template>

<script>
  import WitBlockContentContainer
    from "@/components/common/WitBaseUI/WitBlockContentContainer/WitBlockContentContainer";
  import AiServiceIcon from "@/views/plantation/BriefPanel/AiServiceIcon";
  import FunctionSelPanel from "@/views/plantation/BriefPanel/FunctionSelPanel";
  export default {
    name: "BriefPanel",
    components:{
      WitBlockContentContainer,
      AiServiceIcon,
      FunctionSelPanel
    },
    methods:{
      funcClick(index){
        this.curFuncIndex = index
        this.curSubIndex = 0
      },
      subClick(index){
        this.curSubIndex = index
      }
    },
    computed:{
      curImgUrl(){
        return this.content[this.curFuncIndex][this.curSubIndex]["imgUrl"]
      }
    },
    data(){
      return {
        curFuncIndex: 0,
        curSubIndex: 0,
        title: "AI-将每一个植株都养成大师之作",
        content:[
          [
            {
              title: "病虫害识别",
              iconClass: "icon-debug",
              imgUrl:"/images/plantation/debug.png",
              bgColor: "#17D"
            },
            {
              title: "苗株成长状态识别",
              iconClass: "icon-flower",
              imgUrl:"/images/plantation/growth.jpg",
              bgColor: "#17D"
            },
            {
              title: "产量预测",
              iconClass: "icon-fruit",
              imgUrl:"/images/plantation/predict.png",
              bgColor: "#17D"
            }
          ],
          [
            {
              title: "植株画像",
              iconClass: "icon-experiment",
              imgUrl:"/images/plantation/experiment.jpg",
              bgColor: "#a14444"
            }
          ],
          [
            {
              title: "沃丁专家系统",
              iconClass: "icon-suggest",
              imgUrl:"/images/plantation/suggest.png",
              bgColor: "#b1bb22"
            }
          ],
        ]
      }
    }
  }
</script>

<style lang="scss" scoped>
.brief-panel{
  margin: 100px 0;
  .panel-content{
    .panel-description{
      margin: 50px 0;
      line-height: 27px;
    }
    .icon-desc-block{
      margin-bottom: 50px;
      border-radius: 10px;
      background-color: rgba(35, 176, 77, 0.5)
    }
    .panel-title{
      margin-bottom: 50px;
    }
    .function-select-block{
      margin-bottom: 50px;
    }
    .img-block{
      height: 500px;
    }
  }
}
</style>
