<template>
  <div class="function-sel-panel w-l-row-center-compact">
    <div class="content w-l-row-center-compact" :class="{'active': isActive}" v-on:click="contentClick">
      <div class="page-title fc-black">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "FunctionSelPanel",
    props:{
      index: {
        type: Number,
        require: true
      },
      curIndex:{
        type: Number,
        default: () => 0
      }
    },
    computed:{
      isActive(){
        return this.index === this.curIndex
      }
    },
    methods:{
      contentClick(){
        this.$emit("click", this.index)
      }
    }
  }
</script>

<style lang="scss" scoped>
.function-sel-panel{
  width: 200px;
  height: 150px;
  border-right: 1px solid lightgrey;
  .content{
    position: relative;
    width: 150px;
    height: 100px;
    &:hover{
      cursor: pointer;
      border-radius: 10px;
      background-color: rgba(35, 176, 77, 0.2)
    }
    &.active{
      border-radius: 10px;
      background-color: rgba(35, 176, 77, 0.5)
    }
    &.active:after{
      content: " \\ | / ";
      color: rgba(35, 176, 77, 0.5);
      position: absolute;
      top: calc(100% + 30px);
      left: calc(50% - 8px)
    }
  }
}
.function-sel-panel:last-child{
  border-right: none;
}
</style>
