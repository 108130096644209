<template>
  <div class="ai-plantation-wit">
    <el-main class="plantation-wit-container">
      <img-title-panel bg-img="/images/plantation/tomatoes-greenhouse.jpg" height="600px" mask-color="rgba(0,0,0,0.3)">
        <div class="page-title">AI种植</div>
        <div class="page-title-tip">沃丁科技，形成AI农业领域感知-认知-决策的技术闭环，实现人工智能在农业领域的落地。</div>
      </img-title-panel>
    </el-main>
    <brief-panel />
<!--    <service-info-panel :block-title="perceptionServicesInfo.title" :detail-content="perceptionServicesInfo.content" bg-color="#F0F4F5"/>-->
<!--    <service-info-panel :block-title="cognitionServicesInfo.title" :detail-content="cognitionServicesInfo.content" />-->
<!--    <more-service-panel />-->
  </div>
</template>

<script>
  import ImgTitlePanel from "@/components/content/ImgTitlePanel/ImgTitlePanel";
  import BriefPanel from "@/views/plantation/BriefPanel/BriefPanel";
  import ServiceInfoPanel from "@/views/plantation/ServiceInfoPanel/ServiceInfoPanel";
  import MoreServicePanel from "@/views/plantation/MoreServicePanel/MoreServicePanel";
  export default {
    name: "Plantation",
    components:{
      ImgTitlePanel,
      BriefPanel,
      ServiceInfoPanel,
      MoreServicePanel
    },
    mounted() {
      this.$emit('ContentMounted')
    },
    activated() {
      this.$emit('ContentActivated')
    },
    data(){
      return{
        perceptionServicesInfo:{
          title: "智能感知服务",
          content:[
            {
              imgUrl: "/images/plantation/img_services_a_p03.png",
              title: "病虫害识别",
              content: `用户发现作物异常时，可通过手机拍摄作物叶片或者果实上传到云，即可在毫秒内识别作物是否患有病虫害，患有什么病虫害，并获得防治措施。`
            },
            {
              imgUrl: "/images/plantation/img_services_a_p04.png",
              title: "智能识别生理状态",
              content: `当用户需要判断作物当前生理状态，可通过手机拍摄作物图片上传到云，即可智能识别作物生理状态健康与否，并针对地块特定作物提供个性化生产管理方案。`
            },
            {
              imgUrl: "/images/plantation/img_services_a_p05.png",
              title: "快速数果预测产量",
              content: `通过手机或无人机拍摄，可快速识别果实数量，部分品类可根据果实成熟度等进行分类。告别人工数果，节省大量的时间和人力，更准确掌握果园产量。`
            }
          ]
        },
        cognitionServicesInfo:{
          title: "智能认知服务",
          content:[
            {
              imgUrl: "/images/plantation/img_services_a_p06.png",
              title: "农药复配方案自动评估",
              content: `当用户需要多元复配农药及叶面肥时，在系统中输入农药登记证号即可快速进行复配检测，评估复配方案是否可行，避免药害及药品减效、失效的发生。`
            },
            {
              imgUrl: "/images/plantation/img_services_a_p07.png",
              title: "病虫害用药方案智能推荐",
              content: `病虫害识别引擎可根据病虫害种类、果园所在地区一季作物当前所处的生产周期（物候期），为用户推荐最适合的配药方案建议，指导用户科学用药。`
            }
          ]
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
.ai-plantation-wit{
  .plantation-wit-container{
    padding: 0 !important;
    .page-title{
      margin-bottom: 20px;
    }
  }
}
</style>
