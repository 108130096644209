<template>
  <div class="img-title-panel" :style="{'height': height, 'background-image': 'url(' + bgImg + ')', 'background-size': fit}">
    <div class="mask" v-if="isMaskShow" :style="{'background-color': maskColor}"></div>
    <div class="img-title-panel-wrapper">
      <div class="content item-column-center" :class="posClass">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "ImgTitlePanel",
    props:{
      bgImg:{
        type: String,
      },
      fit:{
        type: String,
        default: () => 'cover'
      },
      height: {
        type: String,
        default: () => '400px'
      },
      maskColor:{
        type: String,
        default: ()=> ''
      },
      textPosition:{
        type: String,
        default: () => 'center'
      }
    },
    computed:{
      isMaskShow(){
        return this.maskColor !== ''
      },
      posClass(){
        if(this.textPosition === 'left'){
          return 'left title-left'
        } else {
          return ''
        }

      }
    }
  }
</script>

<style scoped>
  .img-title-panel{
    position: relative;
    width: 100%;
    min-height: 400px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
  }
  .img-title-panel .mask{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  .img-title-panel .img-title-panel-wrapper{
    max-width: var(--content-wrapper-width);
    width: 100%;
    height: 100%;
    margin: 0 auto;
    position: relative;
  }
  .img-title-panel .img-title-panel-wrapper .content{
    width: 100%;
    height: 100%;
  }
  .img-title-panel .title-left{
    padding-left: 70px;
  }
</style>
